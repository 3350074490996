import React from "react";
import { useSelector } from "react-redux";
import { selectFutureColor } from "../../features/counter/counterSlice";
function ColorChange() {
  const futureColor = useSelector(selectFutureColor);
  return (
    <div className="chgt_color">
      <svg
        className="svg_chgt_color"
        version="1.1.2 (b8e25be833, 2022-02-05)"
        docname="goute.svg"
        viewBox="0 0 800 800"
        xmlns="http://tessard.pro"
      >
        <g transform="scale(38)">
          <path
            d="m10.53 17.02c3.59 0 6.5-2.91 6.5-6.5 0-3.59-2.91-6.5-6.5-6.5-3.59 0-6.5 2.91-6.5 6.5 0 3.59 2.91 6.5 6.5 6.5z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            fill={`rgb(${futureColor}`}
            stroke="#8d8d8d"
          />
          <path
            d="m3.394 17.66 0.13-0.13z"
            opacity="1"
            stroke="#FFFF00"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            d="m3.422 3.812-0.13-0.13z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.98"
            stroke="#f0f"
          />
          <path
            d="m1.056 10.34h-0.08z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.98"
            stroke="#ffa500"
          />
          <path
            d="m10.53 1.056v-0.08z"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.98"
            stroke="#fff"
          />
          <path
            d="m17.39 3.327 0.13-0.13z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.98"
            stroke="#0ff"
          />
          <path
            d="m20.02 10.34h-0.08z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.98"
            stroke="#f00"
          />
          <path
            d="m17.75 17.54-0.13-0.13"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.98"
            stroke="#0f0"
          />
          <path
            d="m10.53 20.02v-0.08z"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.98"
            stroke="#00f"
          />
        </g>
      </svg>
    </div>
  );
}

export default ColorChange;
