import React from 'react'

 const Footer = () => {
  return (
    <footer>
        <div className='footer-container'>
            <div>Projet réalisé par TESSARD Dimitri </div>
            <div>(Uncia) ©2023 tessard.pro </div>
        </div>
    </footer>
  )
}
export default  Footer 