import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSprings, animated } from "@react-spring/web";

const images = [
  "/Svg technologies/amazon-web-services.svg",
  "/Svg technologies/apollo-graphql.svg",
  "/Svg technologies/git.svg",
  "/Svg technologies/google-cloud.svg",
  "/Svg technologies/google.svg",
  "/Svg technologies/graphql.svg",
  "/Svg technologies/jest.svg",
  "/Svg technologies/mongodb.svg",
  "/Svg technologies/node.svg",
  "/Svg technologies/paypal.svg",
  "/Svg technologies/react.svg",
  "/Svg technologies/threejs.svg",
  "/Svg technologies/redux.svg",
];

const configOptions = [
  { friction: 8.8 },
  { friction: 8.5 },
  { friction: 8.2 },
  { friction: 7.9 },
  { friction: 7.6 },
  { friction: 7.3 },
  { friction: 7 },
  { friction: 7.3 },
  { friction: 7.6 },
  { friction: 7.9 },
  { friction: 8.2 },
  { friction: 8.5 },
  { friction: 8.8 },
];
const getRandomPosition = () => {
  const x = Math.floor(Math.random() * 90) + 5; // Random number between 5 and 95
  const y = Math.floor(Math.random() * 90) + 5; // Random number between 5 and 95
  return { top: `${x}%`, left: `${y}%` };
};

export default function SvgBackground({ relance, numImages }) {
  const [positions, setPositions] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const documentHeight = window.innerHeight; //la hauteur de l'ecran

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const onScroll = () => setScrollPosition(window.scrollY);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // console.log(scrollPosition);

  useEffect(() => {
    moveSVGs();
  }, [scrollPosition]);

  const svgElementsRef = useRef([]);
  const svgElements = document.querySelectorAll(".svg_anim");
  svgElementsRef.current = Array.from(svgElements);

  const moveSVGs = useCallback(() => {
    // console.log("moveSVGs");
    if ((scrollPosition <= 800 || scrollPosition === 0) && positions.length) {
      const groupCount = 13;
      const scrollThreshold = 500; // Seuil de défilement après lequel les objets atteignent la position cible
      const targetYPercentage = 85; // Position cible en pourcentage (y)
      const leftStep = 100 / groupCount; // Étape de positionnement horizontal

      const rangeStart = 10; // Début de la plage (20%)
      const rangeEnd = 90; // Fin de la plage (80%)
      const newDestination = [];

      svgElementsRef.current.forEach((element) => {
        element.style.transition = "none";
      });

      // const svgs = Array.from(document.getElementsByClassName("svg_anim"));

      // svgs.forEach((element) => {
      //   element.style.transition = "none";
      // });

      svgElementsRef.current.forEach((svg, index) => {
        const typeValue = parseInt(svg.getAttribute("type"));
        let targetXPercentage = typeValue * leftStep; // Position cible en pourcentage (x)

        targetXPercentage =
          (targetXPercentage / 100) * (rangeEnd - rangeStart) + rangeStart;

        const initialX = parseInt(svg.getAttribute("data-x"), 10);
        const initialY = parseInt(svg.getAttribute("data-y"), 10);

        const distanceXPercentage = targetXPercentage - initialX;
        const distanceYPercentage = targetYPercentage - initialY;

        const progress = Math.min(scrollPosition / scrollThreshold, 1); // Progression entre 0 et 1
        const movementXPercentage = initialX + distanceXPercentage * progress;
        const movementYPercentage = initialY + distanceYPercentage * progress;

        newDestination.push({
          top: `${movementYPercentage.toFixed(2)}%`,
          left: `${movementXPercentage.toFixed(2)}%`,
        });
      });

      setDestinations(newDestination);
    }
  }, [scrollPosition]);

  useEffect(() => {
    const positionsArray = [];

    let imagesArray = [];

    for (let i = 0; i < numImages; i++) {
      const position = getRandomPosition();
      const imageIndex = i % images.length;
      imagesArray.push({ src: images[imageIndex], position: position });
      positionsArray.push(position);
    }

    setPositions(positionsArray);

    return () => {};
  }, [numImages, relance]);

  const springs = useSprings(
    numImages,
    positions.map((position, index) => {
      const config = configOptions[index % 13] || {}; // Utilisez le tableau configOptions pour obtenir la configuration correspondante

      return {
        from: { top: position.top, left: position.left },
        to:
          destinations.length > 0
            ? { top: destinations[index].top, left: destinations[index].left }
            : { top: position.top, left: position.left },
        config: config, // Utilisez la configuration correspondante pour chaque type
      };
    })
  );

  const affichage = useCallback(() => {
    if (positions.length) {
      return (
        <div className="Svg_background" style={{ height: documentHeight }}>
          {springs.map((position, index) => (
            <animated.img
              className={`svg_anim svg${[index % images.length]}`}
              key={index}
              src={images[index % images.length]}
              alt=""
              type={[index % images.length]}
              style={{
                ...position,
              }}
              data-x={positions[index].top}
              data-y={positions[index].left}
            />
          ))}
        </div>
      );
    }
    return null;
  }, [documentHeight, positions, springs]);

  return affichage();
}
