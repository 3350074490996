import * as THREE from "three";

// import Stats from 'three/addons/libs/stats.module.js';
// import { GUI } from 'three/addons/libs/lil-gui.module.min.js';
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { selectSecondeColor } from "../../features/counter/counterSlice";
import React from "react";
import { useSelector } from "react-redux";

// import React from 'react'

function CubeSpheresold() {
  // const secondeColor = useSelector(selectSecondeColor);
  const secondeColor = "#ffffff";
  let camera, scene, renderer, controls, stats;

  let mesh;
  const amount = parseInt(window.location.search.slice(1)) || 10;
  const count = Math.pow(amount, 3);

  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2(1, 1);

  const color = new THREE.Color();
  const white = new THREE.Color().setHex(0xffffff);
  init();
  animate();

  function init() {
    const element = document.getElementById("virtuel");
    // camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.1, 100 );
    if (element.offsetWidth !== null && element.offsetHeight !== null) {
      camera = new THREE.PerspectiveCamera(
        60,
        element.offsetWidth / element.offsetHeight,
        0.1,
        100
      );
      camera.position.set(amount, amount, amount);
      camera.lookAt(0, 0, 0);

      scene = new THREE.Scene();
    }
    // Convertir la couleur hexadécimale en entier décimal
    const myColorInt = parseInt(secondeColor.replace("#", "0x"));
    const light = new THREE.HemisphereLight(myColorInt, 0x888888);
    light.position.set(0, 1, 0);
    scene.add(light);

    const geometry = new THREE.IcosahedronGeometry(0.5, 3);
    const material = new THREE.MeshPhongMaterial({ color: 0xffffff });

    mesh = new THREE.InstancedMesh(geometry, material, count);

    let i = 0;
    const offset = (amount - 1) / 2;

    const matrix = new THREE.Matrix4();

    for (let x = 0; x < amount; x++) {
      for (let y = 0; y < amount; y++) {
        for (let z = 0; z < amount; z++) {
          matrix.setPosition(offset - x, offset - y, offset - z);

          mesh.setMatrixAt(i, matrix);
          mesh.setColorAt(i, color);

          i++;
        }
      }
    }

    scene.add(mesh);

    //

    // const gui = new GUI();
    // gui.add( mesh, 'count', 0, count );

    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true }); //alpha pour transparence
    renderer.setClearColor(0x000000, 0); //pour transparence
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(element.offsetWidth, element.offsetHeight);
    let espaceVirtuelDiv = document.querySelector(".virtuel");
    // console.log(espaceVirtuelDiv)
    espaceVirtuelDiv.appendChild(renderer.domElement);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.enableZoom = false;
    controls.enablePan = false;
    // Autorisez la rotation sans limite d'angle de l'objet avec la souris

    controls.minAzimuthAngle = -Infinity;
    controls.maxAzimuthAngle = Infinity;
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI;

    controls.dampingFactor = 0.03; // Augmenter le damping (0.1 est une valeur de référence, ajustez selon vos besoins)
    // controls.autoRotate = true; // Activer la rotation automatique
    // controls.autoRotateSpeed = 0.2; // Ajuster la vitesse de rotation automatique (vous pouvez expérimenter avec différentes valeurs)
    controls.enableInertia = false;
    controls.inertiaFactor = 100.0001; // Augmenter l'inertie (ajustez selon vos besoins)
    // stats = new Stats();
    // espaceVirtuelDiv.appendChild( stats.dom );

    window.addEventListener("resize", onWindowResize);
    document.addEventListener("mousemove", onMouseMove);
  }

  function onWindowResize() {
    const element = document.getElementById("virtuel");
    if (element.offsetWidth !== null && element.offsetHeight !== null) {
      camera.aspect = element.offsetWidth / element.offsetHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(element.offsetWidth, element.offsetHeight);
      // renderer.setSize( 500, 500 );
    }
  }

  function onMouseMove(event) {
    const element = document.getElementById("virtuel");
    // Obtenir la position de l'élément dans la page
    //  console.log(element)
    if (element !== null && element !== null) {
      const rect = element.getBoundingClientRect();
      const offsetX = rect.left + window.scrollX;
      const offsetY = rect.top + window.scrollY;
      event.preventDefault();

      // Calculer la position de la souris par rapport à l'élément avec décalage
      mouse.x = ((event.clientX - offsetX) / element.offsetWidth) * 2 - 1;
      mouse.y = -((event.clientY - offsetY) / element.offsetHeight) * 2 + 1;
    }
  }

  function animate() {
    requestAnimationFrame(animate);

    controls.update();

    raycaster.setFromCamera(mouse, camera);

    const intersection = raycaster.intersectObject(mesh);

    if (intersection.length > 0) {
      const instanceId = intersection[0].instanceId;

      mesh.getColorAt(instanceId, color);

      if (color.equals(white)) {
        mesh.setColorAt(instanceId, color.setHex(Math.random() * 0xffffff));

        mesh.instanceColor.needsUpdate = true;
      }
    }

    render();

    // stats.update();
  }

  function render() {
    renderer.render(scene, camera);
  }
}

export default CubeSpheresold;
