import React, { useCallback, useState, useMemo } from "react";
import {
  MapContainer,
  TileLayer,
  useMapEvent,
  useMap,
  Rectangle,
  Polygon,
  Circle,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useEventHandlers } from "@react-leaflet/core";

// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

const BOUNDS_STYLE = { weight: 1 };

function MinimapBounds({ parentMap, zoom }) {
  const minimap = useMap();

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    (e) => {
      parentMap.setView(e.latlng, parentMap.getZoom());
    },
    [parentMap]
  );
  useMapEvent("click", onClick);

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds());
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds());
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), zoom);
  }, [minimap, parentMap, zoom]);

  // Listen to events on the parent map
  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), []);
  useEventHandlers({ instance: parentMap }, handlers);

  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />;
}

function MinimapControl({ position, zoom }) {
  const parentMap = useMap();
  const mapZoom = zoom || 1;

  // Memoize the minimap so it's not affected by position changes
  const minimap = useMemo(
    () => (
      <MapContainer
        style={{ height: 120, width: 120 }}
        center={parentMap.getCenter()}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
      </MapContainer>
    ),
    []
  );

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  return (
    <div className={positionClass}>
      <div className="leaflet-control leaflet-bar">{minimap}</div>
    </div>
  );
}

const MapContact = () => {
  // const secondeColor = useSelector(selectSecondeColor);

  // const position = [46.389236497229135, 6.437032205190278];//Suisse
  const position = [44.802184, -0.605804];//Suisse
  // const lausanne = [
  //   [46.50543128359115, 6.661080237859789],
  //   [46.507762230679134, 6.624324893898934],
  //   [46.51793379465199, 6.586213194258028],
  //   [46.50775639329218, 6.551899305422761],
  //   [46.51596205275762, 6.506738743555585],

  //   [46.47749630933338, 6.4499397227428235],
  //   [46.501300083954945, 6.441840975049868],
  //   [46.54550201146055, 6.449442449267506],
  //   [46.60175928354108, 6.560249392235873],
  //   [46.55495711370876, 6.697149207823605],

  //   [46.526015476575736, 6.71634230198479],
  // ];

  const beseOptions = { color: "#4A90E2" };

  return (
    <div className="map_contact">
      <MapContainer center={position} zoom={11.8} scrollWheelZoom={true}>
        {/* <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        /> */}
        <TileLayer
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
        />
        {/* <Polygon pathOptions={beseOptions} positions={lausanne} /> */}
        <Circle
          center={[44.802184, -0.605804]}
          pathOptions={beseOptions}
          radius={8000}
        />
        <MinimapControl position="topright" zoom={2.5} />
      </MapContainer>
    </div>
  );
};

export default MapContact;
