import React from "react";
// import { Rings } from  'react-loader-spinner'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Contact from "./component/Contact/Contact";
import Welcome from "./component/Welcome/Welcome";
import Footer from "./component/Footer/Footer";
import Header from "./component/Header/Header";

// import ThreeJSComponent from "./component/Competences/DossierTestThree/CubeRond";
// import MapContact from "./component/Contact/Map";
// import Logo from "./component/Header/Logo";
import LifeLine from "./component/LifeLine/LifeLine";
// import { MyApp } from "./component/AffichagePDF/PDFViewer";
import { Helmet } from "react-helmet-async";
import Cv from "./component/Cv/Cv";

function App() {
  const metaTitle =
    "Dimitri Tessard - Développeur React, MERN et Node.js - Portfolio";
  // const metaDescription = "Découvrez le portfolio de Dimitri Tessard, un développeur expérimenté spécialisé dans les technologies React, MERN et Node.js. Explorez ses projets passionnants et sa maîtrise des dernières tendances en développement web.";
  // const metaKeywords = "Développeur React, Développeur MERN, Développeur Node.js, Portfolio, Projets Web, Expérience, Technologie de pointe, Développement Front-End, Développement Back-End, Développement THREE.JS, API, Rest API, RESTful";
  // const metaAuthor = "Dimitri Tessard";
  // const metaLanguage = "fr";
  // const metaImage = "https://tessard.pro/LOGO.ico";
  // const metaCanonicalUrl = "https://tessard.pro/";
  // const metaSocialProfiles = {
  //   linkedin: "https://www.linkedin.com/in/tessard/",
  //   github: "https://github.com/MrTESSARD/"
  // }
  return (
    <>
      <Router>
        <Helmet prioritizeSeoTags>
          <title>{metaTitle}</title>
          {/* <meta name="description" content={metaDescription} /> */}
          {/* <meta name="keywords" content={metaKeywords} />
        <meta name="author" content={metaAuthor} />
        <meta name="language" content={metaLanguage} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="fr_FR" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={metaCanonicalUrl} />
        {/* Intégration des liens vers les profils sociaux 
        {metaSocialProfiles.linkedin && <link rel="noopener noreferrer" href={metaSocialProfiles.linkedin} />}
        {metaSocialProfiles.github && <link rel="noopener noreferrer" href={metaSocialProfiles.github} />} */}
        </Helmet>
        <Header />

        <Routes>
          <Route exact path="/" element={<Welcome />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/lifeline" element={<LifeLine />} />
          <Route exact path="/cv" element={<Cv />} />
          {/* <Route path="*" element={<LifeLine />} /> */}
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
