function DateCompter({date}) {
  const currentDate = new Date();
  const timeDiff = currentDate.getTime() - new Date(date).getTime(); // Différence de temps en millisecondes
  const weeks = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7)); // Conversion en semaines
  const months = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30)); // Conversion en mois
  const years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365)); // Conversion en années

  if (years > 0) {
    return `${years} an${years > 1 ? "s" : ""}`;
  } else if (months > 0) {
    return `${months} mois`;
  } else {
    return `${weeks} semaine${weeks > 1 ? "s" : ""}`;
  }
}

export default DateCompter;
