// import React, { useState, useEffect } from "react";
export default function Certificat() {
  return (
    <img
      className="svg_lifeline"
      src="/svg/certificat.svg"
      alt=""
    />
  );
}
