import React, { useState, useRef, useEffect } from "react";
import Baby from "./Baby";
import { Link } from "react-router-dom";
import FlightLanding from "./FlightLanding";
import DateCompter from "../Date/DateCompter";
import Diplome from "./Diplome";
import Passport from "./Passport";
import Wedding from "./Wedding";
import Son from "./Son";
import Certificat from "./Certificat";
import PDFViewer from "../AffichagePDF/PDFViewer";

function ScrollVisibleLine({ children, className }) {
  const [visible, setVisible] = useState(false);
  const divRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const { top, bottom } = divRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (top < windowHeight && bottom >= 0) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      ref={divRef}
      className={`${className} ${visible ? "transform_scale" : ""}`}
    >
      {/* {children} */}
    </div>
  );
}
function ScrollVisibleParagraphe({ children, className }) {
  const [visible, setVisible] = useState(false);
  const divRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const { top, bottom } = divRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (top < windowHeight && bottom >= 0) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      ref={divRef}
      className={`${className} ${visible ? "transform_paragraphe" : ""}`}
    >
      {children}
    </div>
  );
}

function generateKey() {
  const length = 8;
  const chars = "0123456789abcdef";
  let key = "";
  for (let i = 0; i < length; i++) {
    key += chars[Math.floor(Math.random() * chars.length)];
  }
  return key;
}

// const key = generateKey();

function LifeLine() {
  const [showDiv, setShowDiv] = useState(false);
  const [displayedDiv, setDisplayedDiv] = useState(false);
  const divRef = useRef(null);
  const [lienPdf, setLienPdf] = useState(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !event.target.closest(".modal-content") &&
        !event.target.closest(".svg_lifeline") &&
        displayedDiv
      ) {
        setDisplayedDiv(false);
        // console.log("couper")
      }
    }
    if (displayedDiv && !showDiv) {
      document.addEventListener("click", handleClickOutside);
    }

    if (showDiv) {
      setDisplayedDiv(true);

      setShowDiv(false);
    }

    // console.log(`showDiv ${showDiv} - displayedDiv ${displayedDiv}`)
  }, [showDiv, displayedDiv]);

  function handleButtonClick(link) {
    if (!displayedDiv) {
      setShowDiv(true);
      setLienPdf(link);
    }
  }
  return (
    <>
      <main className="projects-overview">
        <section className="section_life_line">
          {/* <div className="container_lifeline">
            <div className="container_photos">
              <div className="container_images_lifeline">
                <img
                  src="./ImgProjets/MarvelQuiz.JPG"
                  alt="Marvel_quiz"
                  width="200"
                  height="200"
                />
                Photos
              </div>
              <div className="line_group_lifeline">
                <ScrollVisibleLine className="line_end_lifeline"></ScrollVisibleLine>
              </div>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_photos">
              <div className="container_images_lifeline">
                <img
                  src="./ImgProjets/MarvelQuiz.JPG"
                  alt="Marvel_quiz"
                  width="200"
                  height="200"
                />
                Photos
              </div>
              <div className="line_group_lifeline">
                <ScrollVisibleLine className="line_end_lifeline"></ScrollVisibleLine>
              </div>
            </div>
          </div> */}
          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/Attestation de Fin de Stage Mill-Forma Dimitri TESSARD.pdf"
                    )
                  }
                >
                  <Certificat />

                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      REACT REDUX TYPESCRIPT Niveau Expert. (MILL-FORMA). &nbsp;
                    </span>{" "}
                    J'ai suivi cette formation et obtenu le certificat de
                    réussite.
                    
                    <p>
                      J'ai suivi ce cours à distance avec mes collègues de
                      niveau intermédiaire pour revoir les concepts que je
                      connaissais déjà. Au programme de ce cours : Compréhension
                      avancée de <span>React</span>, y compris la gestion de
                      l'état, les <span>Hooks</span>, les <span>Portals</span>{" "}
                      et les <span>Refs</span>.
                    </p>
                    <p>
                      - Expertise dans la création de composants hautement
                      performants et réutilisables.
                    </p>
                    <p>
                      - Utilisation de middleware avancés (comme{" "}
                      <span>Redux</span>
                      Saga) pour gérer des flux de données complexes.
                    </p>
                    <p>
                      - Conception d'architectures <span>Redux</span> évolutives
                      pour des applications de grande envergure.
                    </p>
                    <p>
                      - Maîtrise des types génériques et des interfaces
                      avancées.
                    </p>
                    <p>
                      - Création de types complexes pour <span>React</span>,
                      <span>Redux</span> et les modèles de données.
                    </p>
                    <p>
                      - Analyse avancée des performances de l'application avec
                      des outils de profilage.
                    </p>
                    <p>
                      - Mise en place de tests unitaires et d'intégration
                      complexes pour garantir une couverture de code optimale.
                    </p>
                    <p>
                      - Débogage avancé, tests de rendu, et gestion des erreurs.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2024-06-05"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/Certificat-VueJSetFirestoreCreruneSinglePageApplication-Dimitri-TESSARD.pdf"
                    )
                  }
                >
                  <Certificat />

                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      VueJS et Firestore : Créer une Single Page Application.
                      (SKILLEOS). &nbsp;
                    </span>{" "}
                    J'ai suivi cette formation et obtenu le certificat de
                    réussite.
                    <p>
                      J'ai suivi ce cours en ligne, qui met l'accent sur
                      l'utilisation du Framework JavaScript <span>VueJS</span>{" "}
                      en Front et de <span>Firestore</span> en Back pour créer
                      des Single Page Applications. Au programme de ce cours :
                    </p>
                    <p>
                      - Création d'une DAL (Data Access Layer) pour coupler
                      faiblement le Front avec le Backend <span>Firestore</span>
                      .
                    </p>
                    <p>
                      - Astuces de professionnels pour le développement rapide
                      d'applications VueJS, notamment l'utilisation de{" "}
                      <span>Vue CLI 3</span> et des snippets de "Vue VS Code
                      Snippets".
                    </p>
                    <p>
                      - Implémentation d'une fonctionnalité d'"edit in place"
                      permettant aux utilisateurs d'éditer du contenu de manière
                      conviviale.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2023-12-19"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>
          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/JAVA_Certificat-JavalesFondamentaux-Dimitri-TESSARD.pdf"
                    )
                  }
                >
                  <Certificat />

                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Java : les Fondamentaux (SKILLEOS).&nbsp;
                    </span>{" "}
                    J'ai suivi cette formation et obtenu le certificat de
                    réussite.
                    <p>
                      J'ai suivi ce cours en ligne pour apprendre les
                      fondamentaux du langage de programmation <span>Java</span>
                      . Au programme de ce cours :
                    </p>
                    <p>
                      - Découverte du langage <span>Java</span>, son histoire,
                      et son utilisation par de nombreux développeurs à travers
                      le monde.
                    </p>
                    <p>
                      - Apprentissage des fondamentaux de la programmation en
                      Java à travers des vidéos instructives et des exercices
                      pratiques.
                    </p>
                    <p>
                      - Exploration des concepts tels que les variables, les
                      opérateurs, les structures de contrôle, les notions de
                      classe et d'objet, l'héritage, et la gestion des erreurs.
                    </p>
                    <p>
                      - Acquisition des connaissances nécessaires pour
                      développer des applications en Java avec les technologies
                      telles que <span>Java SE</span>, <span>Java EE</span>,{" "}
                      <span>Spring Framework</span>, et <span>JUnit</span>.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2023-11-19"} />
                </p>
                <p className="info_simu_git">SKILLEOS</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>
          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <Link to="https://www.udemy.com/certificate/UC-3baf6ff4-50e0-4c29-986d-06f63da3fb7a/">
                  <div className="svg_lifeline">
                    <Certificat />

                    <span className="svg_blur"></span>
                  </div>
                </Link>

                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Cours complet Next.js (UDEMY)&nbsp;
                    </span>
                    J'ai suivi cette formation et obtenu le certificat de
                    réussite.
                    <p>
                      J'ai suivi ce cours exhaustif sur <span>Next.js</span>, le
                      Framework de <span>React</span>, qui apporte des
                      fonctionnalités essentielles pour optimiser le
                      développement avec React.
                    </p>
                    <p>
                      - Next.js complète React en fournissant un cadre de
                      travail complet tout en préservant les fonctionnalités
                      phares telles que les composants, l'état, les propriétés,
                      les <span>hooks</span>, le <span>contexte</span>, etc.
                    </p>
                    <p>
                      Avantages et fonctionnalités de Next.js :
                      <ol>
                        <li>
                          Un système de routing intégré et facile à utiliser
                        </li>
                        <li>
                          Création de pages statiques rapides et favorables au
                          SEO
                        </li>
                        <li>
                          Optimisation complète des pages, du{" "}
                          <code>&lt;head&gt;</code> aux images
                        </li>
                        <li>Rendu côté serveur en option</li>
                        <li>
                          Utilisation simplifiée et performante des données (
                          <span>API</span>, base de données, etc.)
                        </li>
                        <li>
                          Création d'<span>API</span> directement avec Next.js
                        </li>
                        <li>Et bien plus...</li>
                      </ol>
                    </p>
                    <p>
                      Contenu du cours :
                      <ol>
                        <li>
                          Rappel sur <span>React</span> &{" "}
                          <span>JavaScript</span>
                        </li>
                        <li>Système de routing avec Next.js</li>
                        <li>Optimisation des pages avec Next.js</li>
                        <li>
                          Rendu de pages, rendu côté serveur et utilisation de
                          données
                        </li>
                        <li>Projet pratique : Blog</li>
                        <li>
                          Création d'<span>API</span> avec Next.js
                        </li>
                        <li>Déploiement</li>
                      </ol>
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2023-07-14"} />
                </p>
                {/* Remplacez [DATE_DE_OBTENTION_DU_CERTIFICAT] par la date à laquelle vous avez obtenu le certificat. */}
                <p className="info_simu_git">UDEMY</p>
                {/* Remplacez [NOM_DE_LA_PLATEFORME] par le nom de la plateforme où vous avez suivi le cours. */}
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/Certificat-GitetGitHublesFondamentaux-DIMITRI-TESSARD.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>

                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Git et GitHub : les Fondamentaux (Skilleos). &nbsp;
                    </span>
                    <p></p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2023-03-27"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <Link to="https://www.udemy.com/certificate/UC-229db0f1-f6c1-432e-b055-fc8aa9dd0009/">
                  <div className="svg_lifeline">
                    <Certificat />

                    <span className="svg_blur"></span>
                  </div>
                </Link>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Fullstack React 17, GraphQL, Apollo, Google Sign & Paypal
                      (UDEMY). &nbsp;
                    </span>
                    J'ai suivi cette formation et obtenu le certificat de
                    réussite.
                    <p>
                      Avec cette formation j'ai eu l'occasion de revisiter
                      certaines technologies que je connaissais déjà, mais
                      également d'apprendre de nouvelles choses.
                    </p>
                    <p>
                      - Au cours de cette formation, j'ai appris à utiliser des
                      technologies modernes telles que <span>Mongo</span>,
                      <span>Express</span>, <span>React 17</span>,
                      <span>Node (MERN)</span>,<span> GraphQL</span>,
                      <span> Apollo Client</span>, <span>Paypal</span>,
                      <span> Google Auth</span>, <span>Redux</span>,
                      <span> Google Login</span>, <span> cloud MongoDB </span>{" "}
                      et
                      <span> AWS</span>.
                    </p>
                    <p>
                      - J'ai apris appris comment créer un cluster et une base
                      de données <span>NoSQL</span> sur le
                      <span> cloud AWS</span>, ainsi que des collections pour
                      stocker les données. J'ai également étudié comment
                      utiliser l'<span>API Paypal</span> pour effectuer des
                      transactions financières en toute sécurité et comment
                      intégrer
                      <span> Google Sign</span> & <span>Paypal</span> dans une
                      application. J'ai également utilisé la
                      <span> Google Cloud Platform</span> pour déployer mon
                      application et fournir une expérience utilisateur fluide
                      et rapide.
                    </p>
                    <p>
                      - Enfin, j'ai appris comment créer des API RESTful avec
                      Node et ExpressJS pour communiquer avec notre base de
                      données et fournir des services à nos utilisateurs. Cette
                      formation m'a donné une compréhension solide de la stack{" "}
                      <span>MERN</span> (Mongo, Express, React, Node) et m'a
                      permis de développer des applications Fullstack modernes
                      avec des technologies de pointe.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2023-03-10"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <Link to="https://www.udemy.com/certificate/UC-28d042f0-499c-4bc9-84be-0251862a66c6/">
                  <div className="svg_lifeline">
                    <Certificat />

                    <span className="svg_blur"></span>
                  </div>
                </Link>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Créer un site eLearning avec LMS Wordpress +vendre vos
                      cours (UDEMY). &nbsp;
                    </span>
                    J'ai suivi cette formation et obtenu le certificat de
                    réussite.
                    <p>
                      Cette formation était une occasion unique pour moi de
                      découvrir les technologies associées à la création d'une
                      plateforme eLearning.
                    </p>
                    <p>
                      - J'ai appris à utiliser différentes technologies telles
                      que
                      <span> Paypal</span>, <span>Stripe</span>,
                      <span>WooCommerce</span> et
                      <span>Mailchimp</span> pour créer une expérience de
                      paiement en ligne facile et sécurisée pour les
                      utilisateurs. J'ai également appris comment utiliser des
                      outils de développement tels que
                      <span> PRETTY LINKS</span>, <span>wp-rocket</span>,
                      <span> Duplicator</span>,
                      <span> All-in-One WP Migration</span>
                      pour améliorer les performances et la sécurité de mon
                      site.
                    </p>
                    <p>
                      - En outre, nous avons utilisé des constructeurs de pages
                      populaires tels que <span>Elementor</span>,
                      <span> Visual Composer</span> et <span>Avada</span> pour
                      créer des mises en page élégantes et professionnelles pour
                      notre plateforme eLearning. Cette formation m'a permis de
                      comprendre comment concevoir et développer une plateforme
                      eLearning de qualité, en utilisant les dernières
                      technologies de développement web.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2023-02-27"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <Link to="https://www.udemy.com/certificate/UC-7a6b2dcc-2ebb-4afe-a814-5b5aecb0ddcf/">
                  <div className="svg_lifeline">
                    <Certificat />
                    <span className="svg_blur"></span>
                  </div>
                </Link>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Développement Web JavaScript : Node.JS & MongoDB, archi.
                      MVC (UDEMY). &nbsp;
                    </span>
                    J'ai suivi cette formation et obtenu le certificat de
                    réussite.
                    <p>
                      - Cette formation a été une expérience incroyable pour
                      moi. Pendant la formation, je me suis perfectionné à créer
                      une application Web complète en utilisant des technologies
                      telles que <span>Node.JS/Express</span>,
                      <span> MongoDB & Mongoose</span>, <span>Twig</span>,
                      <span> Bootstrap</span> en architecture <span>MVC</span>.
                    </p>
                    <p>
                      - L'installer et la configuration de <span>Node.JS</span>{" "}
                      et
                      <span> Express.JS</span>, j'ai revu la manipulation de
                      <span> MongoDB</span>
                      avec <span>Mongoose</span>, comment utiliser les modules
                      de
                      <span> Node.JS</span>, tels que <span>Body-Parser</span>,
                      <span> Multer</span>,<span> Morgan</span>,
                      <span> Nodemon</span>, <span>Express-Session</span> et
                      <span> FS</span>, pour améliorer la qualité de notre code.
                      Enfin, nous avons appris à réaliser un <span>CRUD</span>
                      (Create, Read, Update, Delete) pour interagir avec la base
                      de données MongoDB. Grâce à cette formation, j'ai acquis
                      une solide compréhension de Node.JS et de son écosystème,
                      ainsi que de la manière de créer des applications Web
                      robustes et évolutives.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2023-02-21"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <Link to="https://www.udemy.com/certificate/UC-7a8b7685-0c58-4c6f-a6c6-dfc17532efe9/">
                  <div className="svg_lifeline">
                    <Certificat />
                    <span className="svg_blur"></span>
                  </div>
                </Link>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      React JS pour Tous - L'Ultime Formation [Plus de 45
                      heures] (UDEMY). &nbsp;
                    </span>
                    J'ai suivi cette formation et obtenu le certificat de
                    réussite.
                    <p>
                      Après avoir passé des semaines à regarder des vidéos, à
                      lire des articles et à faire des exercices, j'ai
                      finalement réussi à apprendre tous les composants, outils
                      et méthodes de cet incroyable framework. Je dois avouer
                      que cela m'a pris beaucoup de temps et d'énergie pour tout
                      comprendre, mais ça en valait vraiment la peine. À
                      présent, je me sens comme un véritable expert en React JS
                    </p>
                    <p>
                      - Au cours de la formation j'ai eu l'occasion d'explorer
                      de nombreux outils et méthodes utiles pour la construction
                      d'applic ations. J'ai appris à utiliser{" "}
                      <span>REACT </span>
                      avec les <span>HOOKS</span>, une méthode très pratique
                      pour créer des composants fonctionnels. J'ai également
                      étudié les différentes versions de React JS, notamment la
                      <span> version 16, 17 et 18</span>. De plus, j'ai vu
                      comment utiliser <span>REDUX</span> pour gérer l'état
                      global de notre application et comment utiliser{" "}
                      <span>Firebase </span>
                      pour stocker des données en temps réel.
                    </p>
                    <p>
                      - J'ai également étudié la façon d'utiliser des{" "}
                      <span>APIs</span> pour récupérer des données à partir de
                      sources externes et nous avons travaillé avec l'
                      <span>API Google Books</span>. J'ai également appris à
                      utiliser des fonctionnalités de React JS telles que{" "}
                      <span>React.lazy</span> pour améliorer les performances de
                      l'application, <span>React-Redux</span> pour simplifier la
                      gestion de l'état global, et
                      <span> localStorage</span> pour stocker des données
                      localement dans le navigateur. Nous avons également
                      utilisé des bibliothèques tierces telles que{" "}
                      <span>Axios</span> pour effectuer des requêtes HTTP,{" "}
                      <span>React-Tooltip</span> pour ajouter des informations
                      contextuelles et
                      <span> React Toastify</span> pour afficher des
                      notifications à l'utilisateur. Enfin, nous avons utilisé
                      <span> React Icons</span> pour ajouter des icônes de haute
                      qualité à notre application.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2023-02-08"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/certificat Adoptez une architecture MVC en PHP 2480279172.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Adoptez une architecture MVC en PHP (OpenClassrooms).
                      &nbsp;
                    </span>

                    <p></p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2022-12-12"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/certificat Modélisez vos bases de données - 5145316110.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Modélisez vos bases de données (OpenClassrooms). &nbsp;
                    </span>
                    <p></p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2022-12-14"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() => handleButtonClick("./Diplomes/Titre_PRO.pdf")}
                >
                  <Diplome />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Enseignement supérieur. Master II (AFPA). &nbsp;
                    </span>
                    J'ai obtenu le diplôme de Concepteur développeur
                    d'applications.
                    <p>Objectifs de la formation etait de :</p>
                    <p>
                      - Concevoir et développer des composants d'interface
                      utilisateur en intégrant les recommandations de sécurité
                    </p>
                    <p>
                      - Concevoir et développer la persistance des données en
                      intégrant les recommandations de sécurité
                    </p>
                    <p>
                      - Concevoir et developper une application multicouche
                      répartie en intégrant les recommandations de sécurité.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2022-12-01"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/certificat WEB RESPONSIVE cert-1162-10655337.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Responsive Web Design course (sololearn). &nbsp;
                    </span>
                    J'ai suivi cette formation et obtenu le certificat de
                    réussite.
                    <p>
                      Cette formation a été une expérience enrichissante pour
                      moi. J'ai appris les concepts de base du design
                      <span> web responsive</span>, y compris les
                      <span> grilles flexibles</span>, les
                      <span> @médias queries</span>, <span>viewport </span>
                      pour rendre un site web plus réactif.
                    </p>
                    <p>
                      - J'ai étudié les avantages et les inconvénients des
                      différentes approches pour la conception d'un site web,
                      ainsi que les techniques pour rendre un site web plus
                      flexible en utilisant le <span>flexbox</span>. Nous avons
                      étudié les meilleures pratiques en matière de conception
                      web et les tendances actuelles en matière de design. J'ai
                      également appris à utiliser des unités de mesure telles
                      que
                      <span> REM</span> pour rendre un site web plus accessible.
                    </p>
                    <p>
                      - J'ai également appris à optimiser les images pour une
                      utilisation sur le web et à utiliser des outils de
                      développement tels que les <span>DevTools </span>
                      de Google Chrome pour améliorer les performances du site
                      web. Cette formation m'a permis de comprendre comment
                      créer des sites web qui fonctionnent de manière optimisée
                      pour toutes les plateformes, des ordinateurs de bureau aux
                      smartphones en passant par les tablettes.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2022-06-14"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/certificat REACT REDUX -1097-10655337.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      React + Redux course (sololearn). &nbsp;
                    </span>
                    <p></p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2022-05-08"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/certificat PHP-1059-10655337.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      PHP course (sololearn). &nbsp;
                    </span>
                    J'ai suivi cette formation et obtenu le certificat de
                    réussite.
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2021-11-11"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/certificat TESSARD_Dimitri_MOOC.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      MOOC (SecNum académie - ANSSI). &nbsp;
                    </span>
                    J'ai suivi cette formation en partenariat avec l'Agence
                    nationale de la sécurité des systèmes d'information et a
                    obtenu le certificat avec un score de 100% de réussite.
                    <p>
                      - Cette formation m'a permis d'acquérir des connaissances
                      approfondies dans plusieurs domaines de la sécurité
                      informatique. Nous avons commencé par étudier le{" "}
                      <span>panorama de la SSI</span> (sécurité des systèmes
                      d’information), en passant en revue les menaces courantes,
                      les techniques d'attaque, et les méthodes pour prévenir
                      les intrusions.
                    </p>
                    <p>
                      - Nous avons également abordé des sujets tels que la{" "}
                      <span>sécurité de l'authentification</span>, qui traite
                      des techniques d'authentification et d'identification des
                      utilisateurs, ainsi que la{" "}
                      <span>sécurité sur Internet</span>, qui couvre les aspects
                      tels que la confidentialité, l'intégrité et la
                      disponibilité des données sur Internet.
                    </p>
                    <p>
                      - Enfin, nous avons étudié la
                      <span> sécurité du poste de travail et nomadisme</span>,
                      qui traite des risques associés à l'utilisation d'un
                      ordinateur portable ou d'un téléphone mobile en dehors de
                      l'environnement sécurisé d'un bureau. Cette formation m'a
                      donné une compréhension approfondie de la sécurité
                      informatique et m'aidera à mieux protéger mes systèmes et
                      mes données dans l'avenir.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2021-11-09"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/certificat Kotlin cert-10655337-1160.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Kotlin (UDEMY). &nbsp;
                    </span>

                    <p></p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2022-01-06"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <Link to="https://www.udemy.com/certificate/UC-149f7afd-bc35-4f9c-8b4b-bcf0b894c777/">
                  <div className="svg_lifeline">
                    <Certificat />
                    <span className="svg_blur"></span>
                  </div>
                </Link>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Conception de bases de données et langage SQL (UDEMY).
                      &nbsp;
                    </span>
                    J'ai suivi cette formation et obtenu le certificat de
                    réussite.
                    <p>
                      Cette formation m'a permis de comprendre les principes
                      fondamentaux de la conception de bases de données, ainsi
                      que les concepts avancés du langage SQL.
                    </p>
                    <p>
                      - J'ai appris à concevoir des modèles conceptuels de
                      données
                      <span> (MCD)</span>, des modèles logiques de données
                      <span> (MLD) </span>et des modèles physiques de données
                      <span> (MPD) </span>pour mieux comprendre la structure de
                      la base de données. En outre, j'ai appris à utiliser
                      l'environnement
                      <span> WAMP</span> pour créer et gérer une base de
                      données. Nous avons également étudié en détail le langage
                      SQL et ses commandes, y compris les{" "}
                      <span>sous-requêtes</span>, les
                      <span> intégrités relationnelles </span>
                      et les <span>fonctions</span> etc.
                    </p>
                    <p>
                      - Cette formation m'a permis de comprendre comment
                      structurer une base de données pour optimiser les
                      performances et la sécurité, ainsi que de maîtriser les
                      compétences nécessaires pour interroger une base de
                      données en utilisant le langage SQL.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2022-01-01"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/certificat JQuery-1082-10655337.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      jQuery course (sololearn)&nbsp;
                    </span>
                    <p></p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2021-11-03"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/certificat JavaScript -10655337-1124.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      JavaScript (sololearn)&nbsp;
                    </span>
                    <p></p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2021-10-15"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/certificat SQL -1060-10655337.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      SQL course (sololearn)&nbsp;
                    </span>
                    <p></p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2021-09-10"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div className="svg_lifeline" style={{ cursor: "auto" }}>
                  <Son />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Famille</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Naissance.&nbsp;
                    </span>
                    Avant la naissance de mon fils, j'ai décidé de changer de
                    methier.
                    <p>
                      Concepteur développeur d'applications. Cet changement dans
                      ma cariere m'a permis de découvrir un métier
                      incroyablement intéressant et épanouissant
                      professionnellement. Je suis heureux de pouvoir travailler
                      dans un domaine qui me passionne tout en ayant un
                      équilibre entre ma vie professionnelle et personnelle.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2021-01-01"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div
                  className="svg_lifeline"
                  onClick={() =>
                    handleButtonClick(
                      "./Diplomes/C++ certificat C++ -1051-10655337.pdf"
                    )
                  }
                >
                  <Certificat />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation </h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      C++ course (sololearn) &nbsp;
                    </span>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2018-11-04"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div className="svg_lifeline" style={{ cursor: "auto" }}>
                  <Wedding />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">État civil </h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">Mariage. &nbsp;</span>
                    J'ai rencontré l'amour de ma vie et nous avons commencé à
                    construire notre vie ensemble.
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2018-06-01"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div className="svg_lifeline" style={{ cursor: "auto" }}>
                  <Passport />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">État civil </h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Nationalité francaise.&nbsp;
                    </span>
                    Après avoir suivi toutes les étapes et rempli toutes les
                    conditions nécessaires, j'ai obtenu la nationalité
                    francaise.
                    <p>
                      C'est un honneur pour moi de rejoindre cette grande
                      nation, riche de son histoire et de sa culture. Cocorico !
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2013-01-01"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div className="svg_lifeline" style={{ cursor: "auto" }}>
                  <FlightLanding />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">France</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Déménagement en France &nbsp;
                    </span>
                    avec une demande d'asil politique.
                    <p>
                      En raison de risques politiques dans mon pays, ma famille
                      et moi avons dû quitter notre foyer et partir dans un
                      nouvel endroit pour notre sécurité. Quitter sa patrie peut
                      être une expérience émotionnelle éprouvante et cela a été
                      un moment difficile pour moi. Cependant, je savais que
                      c'était la meilleure décision pour la sécurité de ma
                      famille et j'ai fait de mon mieux pour m'adapter à ma
                      nouvelle vie. Cette période a été une période de
                      transition pour moi, mais j'ai pu me concentrer sur mes
                      études et les activités scolaires pour rester concentré et
                      pour m'adapter à mon nouvel environnement. Cette
                      expérience m'a appris à être résilient et à m'adapter à
                      des situations difficiles, et c'est quelque chose que je
                      porte toujours avec moi aujourd'hui.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"2000-06-01"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div className="svg_lifeline" style={{ cursor: "auto" }}>
                  <Diplome />
                  <span className="svg_blur"></span>
                </div>
                <ScrollVisibleLine className="line_begin_lifeline"></ScrollVisibleLine>
              </div>
              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Éducation</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      École de culture générale (Gymnase).&nbsp;
                    </span>
                    Ma réussite scolaire durant cette période a été l'une de mes
                    fiertés, et cela a été en grande partie grâce à mon intérêt
                    et ma passion pour les matières tels que l'algébre la
                    phisique et la chimie. J'ai obtenu la mention "Très bien"
                    <p>
                      Durant mon parcours au lycée, j'ai également participé à
                      des compétitions d'algèbre sur la vitesse de résolution
                      des fonctions les plus complexes. J'ai adoré la sensation
                      d'adrénaline qui accompagnait chaque compétition et j'ai
                      été fier de mes performances dans ces épreuves. Bien que
                      cela puisse sembler intense, je pense que cela m'a aidé à
                      développer une meilleure capacité à gérer le stress et à
                      travailler efficacement dans des situations à haute
                      pression.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"1999-06-01"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>

          <div className="container_lifeline">
            <div className="container_line">
              <div className="line_group_lifeline">
                <div className="svg_lifeline" style={{ cursor: "auto" }}>
                  <Baby />
                  <span className="svg_blur"></span>
                </div>
              </div>

              <ScrollVisibleParagraphe className="container_data_lifeline">
                <div className="container_text_lifeline">
                  <h3 className="titre_lifeline_container">Naissance</h3>
                  <h2 className="texte_lifeline_container">
                    <span className="text-accent-primary">
                      Premier jour de travail,&nbsp;
                    </span>
                    dès que j'ai pu utiliser mes mains, j'ai commencé à démonter
                    les objets autour de moi pour comprendre comment ils
                    fonctionnent.
                    <p>
                      Depuis, j'ai canalisé cette curiosité en passion pour les
                      technologies, et j'ai continué à développer mes
                      compétences dans ce domaine tout au long de ma carrière.
                    </p>
                  </h2>
                </div>
                <p className="info_simu_git">
                  Il y a <DateCompter date={"1985-06-01"} />
                </p>
                <p className="info_simu_git">TESSARD</p>
                <p className="info_simu_git">{generateKey()}</p>
              </ScrollVisibleParagraphe>
            </div>
          </div>
          {displayedDiv && (
            <div className="modal" ref={divRef}>
              <div className="modal-content">
                <PDFViewer link={lienPdf} />
              </div>
            </div>
          )}
        </section>
      </main>
    </>
  );
}

export default LifeLine;
