// import React, { useState, useEffect } from "react";
export default function Baby() {
  return (
    <img
      className="svg_lifeline"
      src="/svg/baby.svg"
      alt=""
    />
  );
}
