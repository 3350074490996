// import React, { useState, useEffect } from "react";
export default function Passport() {
  return (
    <img
      className="svg_lifeline"
      src="/svg/passport.svg"
      alt=""
    />
  );
}
