import Typewriter from "typewriter-effect";

import React, { useState } from "react";

function BienwenueMessage({ onStateChange }) {
  let test = false;
  function handleChildStateChange() {
    test = !test;
    onStateChange(!test);
  }
  return (
    <Typewriter
      onInit={(typewriter) => {
        typewriter

          .typeString("Développeur ")
          .typeString("Web")
          .pauseFor(1800)
          .deleteChars(3)
          .callFunction(() => {
            const elements = document.querySelectorAll(".svg10");
            elements.forEach((element) => {
              element.classList.add("evidenceSvg");
            });
          })
          .typeString(
            '<strong><span style="color: #61dafbff;">React</span> </strong>'
          )
          .pauseFor(1800)
          .callFunction(() => {
            const elements = document.querySelectorAll(".svg10");
            elements.forEach((element) => {
              element.classList.remove("evidenceSvg");
            });
          })
          .deleteChars(6)
          .callFunction(() => {
            const elements = document.querySelectorAll(".svg8");
            elements.forEach((element) => {
              element.classList.add("evidenceSvg");
            });
          })
          .typeString(
            '<strong><span style="color: #5fa04eff;">Node</span> </strong>'
          )
          .pauseFor(1800)
          .callFunction(() => {
            const elements = document.querySelectorAll(".svg8");
            elements.forEach((element) => {
              element.classList.remove("evidenceSvg");
            });
          })
          .deleteChars(5)
          .callFunction(() => {
            const elements = document.querySelectorAll(".svg11");
            elements.forEach((element) => {
              element.classList.add("evidenceSvg");
            });
          })
          .typeString(
            '<strong><span style="color: #ffffff;">Three</span> </strong>'
          )
          .pauseFor(1800)
          .callFunction(() => {
            const elements = document.querySelectorAll(".svg11");
            elements.forEach((element) => {
              element.classList.remove("evidenceSvg");
            });
          })
          .deleteChars(6)
          .typeString(
            '<strong><span style="color: #ffffff;">Full Stack</span> </strong>'
          )
          .callFunction(() => {
            handleChildStateChange();
          })

          //   .deleteChars(15)
          //   .deleteAll()
          .start();
        //

        //   .callFunction(() => {
        //     // console.log('String typed out!');
        //   })
        //   .pauseFor(2500)
        //   .deleteAll()
        //   .callFunction(() => {
        //     // console.log('All strings were deleted');
        //   })
        //   .start();
      }}
      options={{
        //   strings: ['Web', 'React'],
        autoStart: true,
        loop: false,
        deleteSpeed: 50,
        //   devMode:true
      }}
    />
  );
}

export default BienwenueMessage;
