import React from "react";
import MapContact from "./Map";
import Formulaire from "./Formulaire";
// import { Email } from '../Mailer/Email';
// import { render } from '@react-email/render';
// import nodemailer from 'nodemailer';

function Contact() {
  return (
    <>
      <section className="section_contact">
        <Formulaire />
        {/* <Email/>*/}

        <MapContact />
      </section>
    </>
  );
}

export default Contact;
