import React, {
  Suspense,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
// import { Rings } from  'react-loader-spinner'
import pignon from "./pignon.gif";
// import Three from "../../features/three/Three";
import { Link } from "react-router-dom";

// import { ThreeSphere } from "./features/three/SphereV1";
// import styled from "styled-components";

// import { useSelector } from "react-redux";
// import { selectSecondeColor } from "../../features/counter/counterSlice";
// import ThreeCard from "../Three/TextReact";
// import Leopard from "../Three/Leopard";
// import Galerie3D from "../Three/Galerie3D";
// import VirtualSpace from "../Three/VirtualSpace";
// import { ThreeSphere } from "../Competences/DossierTestThree/SphereV1";
// import MapContact from "../Contact/Map";
import SvgBackground from "../Background/Svg";
// import Icosahedron from "../Three/Icosahedron";
import BienwenueMessage from "../TypeWriterMessage/BienwenueMessage";
// import CubeSpheres from "../EspaceVirtuel/CubeSpheres";
import CubeSpheresold from "../EspaceVirtuel/CubeSpheresold";
// import { cloneUniforms } from "three";
// import Sample from "../AffichagePDF/Sample.tsx";
// import { Helmet } from 'react-helmet-async';
import axios from "axios";

const NMIcosahedron = React.lazy(() => import("../Three/Icosahedron"));
const NMLeopard = React.lazy(() => import("../Three/Leopard"));
const NMThreeCard = React.lazy(() => import("../Three/TextReact"));
const NMGalerie3D = React.lazy(() => import("../Three/Galerie3D"));

const Icosahedron = React.memo(NMIcosahedron);
const Leopard = React.memo(NMLeopard);
const ThreeCard = React.memo(NMThreeCard);
const Galerie3D = React.memo(NMGalerie3D);

function Welcome() {
  const mailServer = process.env.MAIL_SERVER;
  // const SvgBackground = React.lazy(() => import("../Background/Svg"));

  // const secondeColor = useSelector(selectSecondeColor);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [recharge, setRecharge] = useState(true);
  const numImages = 78;

  const svgElementsRef = useRef([]);
  const svgElements = document.querySelectorAll(".svg_anim");
  svgElementsRef.current = Array.from(svgElements);

  useEffect(() => {
    const svgElements = document.querySelectorAll(".svg_anim");
    svgElementsRef.current = Array.from(svgElements);
  }, []);

  useEffect(() => {
    svgElementsRef.current.forEach((element) => {
      element.classList.add("slow");
    });
  }, [recharge]);

  useEffect(() => {
    // Informations sur le client
    const clientInfo = {
      ipAddress: window.location.hostname,
      userAgent: window.navigator.userAgent,
      timestamp: new Date().toLocaleString(),
    };
    console.log("process.env.MAIL_SERVER", process.env.MAIL_SERVER);
    // Envoi des informations au serveur (vous pouvez également utiliser une requête AJAX vers votre serveur Node.js)
    axios
      .post(`https://${mailServer}:8443/api/saveClientInfo`, clientInfo)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de l'enregistrement des informations sur le client :",
          error
        );
      });
  }, []); // Le tableau vide indique que cette opération d'effet n'a besoin d'être exécutée qu'une seule fois

  function handleButtonClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setIsButtonVisible(false);

    const elements = document.querySelectorAll(".svg_anim");
    elements.forEach((element) => {
      element.classList.add("falling");
    });
    //   const svgElements = document.querySelectorAll(".svg_anim");
    // svgElementsRef.current = Array.from(svgElements);

    CubeSpheresold();
  }

  const handleChildStateChange = useCallback((state) => {
    setRecharge(state);
    // svgElementsRef.current.forEach((element) => {
    //   element.style.transition = "2s cubic-bezier(0, 0, 0.42, 1.46)";
    // });
  }, []);

  const suspence = (
    <div
      style={{
        backgroundImage: `url(${pignon})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "15wv",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    ></div>
  );

  return (
    <>
      {/* <title>Mon titre de page</title>
        <meta name="description" content="Ma description de page" /> */}

      <main className="projects-overview">
        <section id="message" className="hello-section">
          <div className="main-message">
            <h3 className="main_message_salut">Salut,</h3>
            <span className="line_vertical">
              <h1 className="big_heading">Je m'appele Dimitri</h1>
              <h2 className="opacity_05">
                <BienwenueMessage onStateChange={handleChildStateChange} />
              </h2>
              <p className="competence-text">Node / React</p>
            </span>

            <Link to="/contact" className="filled-button" title="Contactez-moi">
              <button className="contact-button">
                <span className="bef"></span>
                <span className="text">Contactez-moi</span>
                <span className="pointSpan"> </span>
                <br />
              </button>
              <div className="bord"></div>
            </Link>
          </div>
          <div className="espace-virtuel">
            <div className="virtuel" id="virtuel">
              {isButtonVisible && (
                <div className="filled-button">
                  <button
                    className="contact-button"
                    onClick={handleButtonClick}
                  >
                    <span className="bef"></span>
                    <span className="text">Interdit d'appuyer!</span>
                    <span className="pointSpan"> </span>
                    <br />
                  </button>
                  <div className="bord"></div>
                </div>
              )}
            </div>
          </div>
        </section>

        <section id="techno-projects" className="techno-section">
          <div className="spacer">
            <Suspense fallback={suspence}>
              <Icosahedron />
            </Suspense>

            {/* <Icosahedron /> */}
          </div>
          <div className="main-projects">
            <h2>Projects</h2>
            <div className="project_wrapper">
              <div>
                <a
                  className="leopard_projet"
                  rel="Galerie Léonard"
                  target="Galerie Léonard"
                  href="https://lumfo.com/"
                  title="Galerie Léonard"
                >
                  <div className="span_ligne">
                    <h3 className="nom_projet">Galerie Léonard</h3>
                    <div className="three_div">
                      {/* <Leopard /> */}

                      <Suspense fallback={suspence}>
                        <Leopard />
                      </Suspense>
                    </div>
                  </div>
                </a>
                <a
                  className="galerie_3d_projet"
                  rel="Galerie 3D"
                  target="Galerie 3D"
                  href="https://leo.tessard.pro/public/index.php?3d=bordeaux&place=Place%20de%20la%20Com%C3%A9die&date=20220306_163049"
                  title="Galerie 3D"
                >
                  <div className="span_ligne">
                    <h3 className="nom_projet">Galerie 3D</h3>
                    <div className="three_div">
                      <Suspense fallback={suspence}>
                        <Galerie3D />
                      </Suspense>
                    </div>
                  </div>
                </a>
                <a
                  className="galerie_3d_projet"
                  rel="Click and Collect"
                  target="Click and Collect"
                  href="http://clickandcollect.tessard.pro/"
                  title="Click and Collect"
                >
                  <div className="span_ligne">
                    <h3 className="nom_projet">Click and Collect</h3>
                    <div className="three_div">
                      <img
                        src="./ImgProjets/clickandcollect_min.JPG"
                        alt="Click and Collect"
                        width="200"
                        height="200"
                        title="Click and Collect"
                      />
                    </div>
                  </div>
                </a>
                <a
                  className="galerie_3d_projet"
                  rel="Bibliothèque"
                  target="Bibliothèque"
                  href="https://biblio.tessard.pro"
                  title="Bibliothèque"
                >
                  <div className="span_ligne">
                    <h3 className="nom_projet">Bibliothèque</h3>
                    <div className="three_div">
                      <img
                        src="./ImgProjets/Biblio1_min.JPG"
                        alt="Bibliothèque"
                        width="200"
                        height="200"
                        title="Bibliothèque"
                      />
                    </div>
                  </div>
                </a>
                <a
                  className="galerie_3d_projet"
                  rel="Marvel Quiz"
                  target="Marvel Quiz"
                  href="https://marvel-quiz-6d041.firebaseapp.com/welcome"
                  title="Marvel Quiz"
                >
                  <div className="span_ligne">
                    <h3 className="nom_projet">Marvel Quiz</h3>
                    <div className="three_div">
                      <img
                        src="./ImgProjets/MarvelQuiz_min.JPG"
                        alt="Marvel_quiz"
                        width="200"
                        height="200"
                        title="Marvel_quiz"
                      />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="competences">
          <div className="competences">
            <div className="competence_card">
              <h3>MERN</h3>
              <div className="texte_competence">
                J'ai une forte expertise dans le développement web utilisant le
                stack MERN, y compris la création d'API RESTful avec Express, la
                gestion de bases de données MongoDB, la création d'interfaces
                utilisateur réactives avec React, le développement côté serveur
                avec Node.js et la création d'applications de commerce
                électronique.{" "}
              </div>
            </div>
            <div className="competence_card">
              <h3>NODE JS</h3>
              <div className="texte_competence">
                J'ai des competences dans le développement web côté serveur avec
                Node.js, y compris la création d'API RESTful, la gestion de
                bases de données NoSQL et la création d'applications évolutives
                et performantes. J'ai une connaissance approfondie des concepts
                de base de Node.js, ainsi que des compétences avancées en
                utilisant des outils tels que Express, Socket.IO et Mongoose. Je
                suis également capable de développer des applications en temps
                réel et de les déployer sur des services de cloud tels que AWS
                et Firebase.
              </div>
            </div>
            <div className="competence_card">
              <h3>API RESTful et GraphQL</h3>
              <div className="texte_competence">
                J'ai des competences dans la création d'API RESTful et de
                GraphQL, et j'ai de l'expérience dans l'utilisation de ces
                technologies pour le développement d'applications web évolutives
                et performantes. J'ai une bonne connaissance des concepts de
                base de ces technologies, ainsi que des compétences avancées en
                utilisant des outils tels que Express, Apollo Server et Prisma
                ORM. Je suis également capable de concevoir et de mettre en
                œuvre des schémas de données complexes pour répondre aux besoins
                des applications.
              </div>
            </div>
            <div className="competence_card">
              <h3>ES7, ES10</h3>
              <div className="texte_competence">
                Je suis un développeur JavaScript expérimenté, avec une
                expertise dans les versions les plus récentes de la langue, y
                compris ES6+. J'ai une bonne connaissance des concepts de base
                de JavaScript, ainsi que des compétences avancées en utilisant
                des outils modernes pour le développement web. Je suis capable
                de travailler avec des fonctions fléchées, des classes, des
                modules, des destructurations, des promesses et d'autres
                fonctionnalités de ES6+. En comparaison avec ES5, ES6+ propose
                des améliorations significatives pour rendre le code plus
                lisible, plus facile à comprendre et plus maintenable.
              </div>
            </div>
            <div className="competence_card">
              <h3>REDUX, Zustand</h3>
              <div className="texte_competence">
                Je suis compétent dans le développement d'interfaces utilisateur
                réactives avec React et Redux, et j'ai une expérience pratique
                dans l'utilisation de ces technologies pour créer des
                applications web évolutives et performantes. J'ai une bonne
                connaissance des concepts de base de React et Redux, ainsi que
                des compétences avancées en utilisant les hooks de Redux tels
                que useSelector, useDispatch, useStore, useMemo et useCallback,
                création d'un store pour gérer l'état global des applications.
                Je suis également capable de concevoir et de mettre en œuvre des
                architectures de code efficaces pour améliorer la maintenabilité
                et la scalabilité des applications.
              </div>
            </div>

            {/* <svg id="cone-construction" class="icon flat-color" width="800px" height="800px" fill="#000000" data-name="Flat Color"  version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
 <path fill="#ff0" id="primary" d="M21,20H19.83L13.88,3.33a2,2,0,0,0-3.76,0L4.17,20H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z" />
 <polygon id="secondary" points="18.4 16 5.6 16 7.38 11 16.62 11" fill="#f00"/>
</svg> */}
            <div className="three" id="canvasCompetenceThree">
              <Suspense fallback={suspence}>
                <ThreeCard />
              </Suspense>
            </div>
          </div>
        </section>
        {/* <Suspense
                        fallback={
                          suspence
                        }
                      > */}
        <SvgBackground numImages={numImages} relance={recharge} />
        {/* </Suspense> */}
      </main>
    </>
  );
}

export default Welcome;
