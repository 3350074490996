import React, { useRef, useState } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const MAIL_SERVER = process.env.REACT_APP_MAIL_SERVER;
console.log("MAIL_SERVER", MAIL_SERVER);
function Formulaire() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [objet, setObjet] = useState("");
  const [message, setMessage] = useState("");
  const toastId = useRef(null);
  // const mailServer = process.env.REACT_APP_MAIL_SERVER

  const Toast = (message, type) => {
    switch (type) {
      case 1:
        toastId.current = toast(message, {
          autoClose: false,
          type: toast.TYPE.INFO,
          theme: "dark",
        });
        break;
      case 2:
        toast.update(toastId.current, {
          render: message,
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
          position: "top-right",
          hideProgressBar: false, //cacher  la barre de progression
          closeOnClick: true, //cache au click
          pauseOnHover: true, //pause hover
          draggable: false, //on peut pas la faire glisser
          progress: undefined,
          theme: "dark",
          bodyClassName: "toastify-color",
        });
        break;
      case 3:
        toast.update(toastId.current, {
          render: message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          position: "top-right",
          hideProgressBar: false, //cacher  la barre de progression
          closeOnClick: true, //cache au click
          pauseOnHover: true, //pause hover
          draggable: false, //on peut pas la faire glisser
          progress: undefined,
          theme: "dark",
          bodyClassName: "toastify-color",
        });
        break;
      case 4:
        toast(message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          position: "top-right",

          theme: "dark",
          bodyClassName: "toastify-color",
        });
        break;

      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      name.length > 3 &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) &&
      objet.length > 5 &&
      message.length > 30
    ) {
      Toast(`Envoie en cours`, 1);
      const axiosConfig = {
        proxy: {
          protocol: "https",
          host: "localhost",
          port: 443,
        },
      };
      console.log("MAIL_SERVER", MAIL_SERVER);
      axios
        // .post("https://13.48.49.74:443/api/send-email", {
        // .post("https://localhost:8443/api/send-email", {//fonctionne avec server local
        // .post("https://vps94717.serveur-vps.net:8443/api/send-email", {//fonctionne avec VPS M
        // .post("https://vps95240.serveur-vps.net:8443/api/send-email", {//fonctionne avec VPS VPS LC1
        .post(`https://${MAIL_SERVER}:8443/api/send-email`, {
          //fonctionne avec VPS VPS LC2
          // .post("https://server.tessard.io:8443/api/send-email", {
          // .post("https://tessard.io:3006/api/send-email", {

          /******** */
          // .post("https://localhost:8443/api/send-email", {//fonctionne en local avec CORS et allarme jaune en Postman
          // .get("http://localhost:3005/api/send-email", {//fonctionne en local avec CORS et allarme jaune en Postman

          /******** */
          name,
          email,
          objet,
          message,
        })
        .then((response) => {
          Toast(`Votre message a bien été envoyé!`, 2);
          setName("");
          setEmail("");
          setObjet("");
          setMessage("");
        })
        .catch((error) => {
          Toast(`Une erreur est survenue lors de l'envoi du message.`, 3);
        });
    } else {
      Toast(`Vérifiez le formulaire SVP`, 4);
    }
  };

  return (
    <div className="text_zone">
      <div className="contact_form">
        <div className="contact_message_titre">
          <h3 className="main_message_salut">Contact</h3>
          <span className="line_vertical">
            <h2 className="opacity_05">
              {/* <BienwenueMessage onStateChange={handleChildStateChange}/> */}
            </h2>
            <p className="competence-text">
            Bienvenue sur ma page de contact. N'hésitez pas à me laisser un message si vous avez des questions, des suggestions ou si vous souhaitez discuter d'un projet potentiel. Je suis toujours ouvert aux nouvelles collaborations et opportunités.
            </p>
            <p className="competence-text">
            Utilisez le formulaire ci-dessous pour m'envoyer un message directement. Je m'efforcerai de vous répondre dans les plus brefs délais.            </p>

            <form id="contact">
              <ul className="contact_ul">
                <li className="contact_nom half">
                  <input
                    required
                    minLength="3"
                    className="input_field"
                    type="text"
                    placeholder="Nom"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </li>
                <li className="contact_email half">
                  <input
                    required
                    className="input_field"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </li>
                <li className="contact_objet">
                  <input
                    required
                    minLength="5"
                    className="input_field"
                    value={objet}
                    placeholder="Objet"
                    onChange={(event) => setObjet(event.target.value)}
                  />
                </li>
                <li className="contact_message">
                  <textarea
                    minLength="30"
                    required
                    className="text_field"
                    value={message}
                    placeholder="Message"
                    onChange={(event) => setMessage(event.target.value)}
                  />
                </li>
              </ul>

              {/* <button type="submit">Envoyer</button> */}

              {/* <Link to="/contact" className="filled-button"> */}
              {/* </Link> */}
            </form>
          </span>
          <div className="aligne_button">
            <div className="filled-button">
              <button className="contact-button" onClick={handleSubmit}>
                <span className="bef"></span>
                <span className="text">Envoyer le message</span>
                <span className="pointSpan"> </span>
                <br />
              </button>
              <div className="bord"></div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}


export default Formulaire;
