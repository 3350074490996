import React from "react";
function Logo() {
  return (
    <svg
      className="svg_logo"
      version="1.1.2 (b8e25be833, 2022-02-05)"
      docname="goute.svg"
      viewBox="0 0 10 10"
      xmlns="http://tessard.pro"
    >
      <g transform="translate(-82.34 -111.9)">
        <g>
          <g
            transform="matrix(.9555 -.8017 .8017 .3739 -5.082e-7 8.719e-7)"
            style={{ fill: "#8d8d8d", strokeWidth: 0.2646 }}
            aria-label="D"
          >
            <path d="m-58 182.7q0 1.87-1.064 2.82-1.064 0.94-2.965 0.94h-2.087v-7.376h2.314q1.157 0 2.004 0.4132 0.8574 0.4132 1.322 1.219 0.4752 0.8058 0.4752 1.983zm-1.622 0.0413q0-1.219-0.5372-1.797-0.5372-0.5785-1.56-0.5785h-0.8367v4.804h0.6715q2.262 0 2.262-2.428z" />
          </g>
          <g
            transform="skewY(25)"
            style={{ fill: "#4A90E2", strokeWidth: 0.2646 }}
            aria-label="T"
          >
            <path d="m88.25 79.97h-1.598v-6.223h-2.053v-1.333h5.704v1.333h-2.053z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
