import React, { useState, useEffect } from 'react';
import pignon from './pignon.gif';

export default function PDFViewer({ link }) {
  const [isLoading, setIsLoading] = useState(true);
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        const response = await fetch(link);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
        setIsLoading(false);
      } catch (error) {
        console.error('Erreur lors du téléchargement du PDF :', error);
        setIsLoading(false);
      }
    };

    fetchPDF();
  }, [link]);
  const suspence = <div
style={{
  backgroundImage: `url(${pignon})`,
  backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '15%',
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
}}
>

</div>

  return (
    <div className="pdf">
      {isLoading ? (
        <div className="pdfViewer">
          {suspence}
        </div>
      ) : (
        <div className="pdfViewer">
          <object
            data={pdfUrl}
            type="application/pdf"
            width="100%"
            height="100%"
            style={{ maxHeight: '85vh', objectFit: 'contain' }}
          >
            <p>
              Votre navigateur ne supporte pas les fichiers PDF.
              <a href={link}>Cliquez ici pour télécharger le PDF.</a>
            </p>
          </object>
        </div>
      )}
    </div>
  );
}
